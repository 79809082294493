var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"video-hero",staticClass:"relative flex justify-center overflow-hidden scrollbar-hide group z-0 bg-lightGray",class:[
    _vm.hideMobile ? 'hidden' : 'flex',
    _vm.hideDesktop ? 'lg:hidden' : 'lg:flex',
    _vm.alignMobile,
    _vm.alignDesktop,
    _vm.marginBottom,
    _vm.mobileSwipeable && 'min-w-[75vw] lg:min-w-0',
    _vm.aspectRatio,
    _vm.aspectRatioDesktop,
  ],attrs:{"vide-hero":""}},[(_vm.$u(_vm.link) !== null)?_c('mp-link',{staticClass:"absolute inset-0 z-30 block",attrs:{"to":_vm.$u(_vm.link)}}):_vm._e(),_vm._v(" "),(_vm.headlines.length)?_c('div',{staticClass:"absolute z-20 flex flex-col w-full h-full gap-10 px-6 pb-4",class:[
      _vm.contentPosition(_vm.headlinePositionMobile, _vm.headlinePositionDesktop),
      _vm.paddingHorizontalDesktop,
    ]},[_vm._l((_vm.headlines),function(headline,headlineIndex){return _c('Headline',{key:'headline-' + headlineIndex,staticClass:"w-full",attrs:{"headline-type":headline.headlineType,"mobile-headline-size":headline.mobileHeadlineSize,"desktop-headline-size":headline.desktopHeadlineSize,"headline-color":headline.headlineColor,"eyebrow":headline.eyebrow,"headline":headline.headline,"lead":headline.lead,"text-align":"inherit"}})}),_vm._v(" "),_c('div',{staticClass:"flex flex-col items-start w-full gap-6",class:[_vm.contentPosition(_vm.linksPositionMobile, _vm.linksPositionDesktop)]},_vm._l((_vm.links),function(heroLink,heroLinkIndex){return _c('Link',_vm._b({key:'heroLink-' + heroLinkIndex,staticClass:"text-sm font-normal tracking-widest lg:text-xl"},'Link',heroLink,false),[_vm._v("\n        "+_vm._s(heroLink.label)+"\n      ")])}),1)],2):_vm._e(),_vm._v(" "),_c('Vue2Video',{ref:"video",staticClass:"object-cover w-full h-full transition duration-300 block lg:hidden",class:[_vm.lowerBrightness],attrs:{"src":_vm.videoSrc || _vm.videoSrcDesktop,"poster":_vm.posterUrl || _vm.posterUrlDesktop}}),_vm._v(" "),_c('Vue2Video',{ref:"video",staticClass:"object-cover w-full h-full transition duration-300 hidden lg:block",class:[_vm.lowerBrightness],attrs:{"src":_vm.videoSrcDesktop || _vm.videoSrc,"poster":_vm.posterUrlDesktop || _vm.posterUrl}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }