<template>
  <div
    ref="video-hero"
    vide-hero
    class="
      relative
      flex
      justify-center
      overflow-hidden
      scrollbar-hide
      group
      z-0
      bg-lightGray
    "
    :class="[
      hideMobile ? 'hidden' : 'flex',
      hideDesktop ? 'lg:hidden' : 'lg:flex',
      alignMobile,
      alignDesktop,
      marginBottom,
      mobileSwipeable && 'min-w-[75vw] lg:min-w-0',
      aspectRatio,
      aspectRatioDesktop,
    ]"
  >
    <!-- LINK -->
    <mp-link
      v-if="$u(link) !== null"
      :to="$u(link)"
      class="absolute inset-0 z-30 block"
    />

    <!-- HEADLINE TEXT -->
    <div
      v-if="headlines.length"
      class="absolute z-20 flex flex-col w-full h-full gap-10 px-6 pb-4"
      :class="[
        contentPosition(headlinePositionMobile, headlinePositionDesktop),
        paddingHorizontalDesktop,
      ]"
    >
      <Headline
        v-for="(headline, headlineIndex) in headlines"
        :key="'headline-' + headlineIndex"
        :headline-type="headline.headlineType"
        :mobile-headline-size="headline.mobileHeadlineSize"
        :desktop-headline-size="headline.desktopHeadlineSize"
        :headline-color="headline.headlineColor"
        :eyebrow="headline.eyebrow"
        :headline="headline.headline"
        :lead="headline.lead"
        text-align="inherit"
        class="w-full"
      />

      <!-- TEXT/LINKS -->
      <div
        class="flex flex-col items-start w-full gap-6"
        :class="[contentPosition(linksPositionMobile, linksPositionDesktop)]"
      >
        <Link
          v-for="(heroLink, heroLinkIndex) in links"
          :key="'heroLink-' + heroLinkIndex"
          v-bind="heroLink"
          class="text-sm font-normal tracking-widest lg:text-xl"
        >
          {{ heroLink.label }}
        </Link>
      </div>
    </div>

    <!-- BACKGROUND MEDIA -->

    <Vue2Video
      ref="video"
      class="object-cover w-full h-full transition duration-300 block lg:hidden"
      :class="[lowerBrightness]"
      :src="videoSrc || videoSrcDesktop"
      :poster="posterUrl || posterUrlDesktop"
    />
    <Vue2Video
      ref="video"
      class="object-cover w-full h-full transition duration-300 hidden lg:block"
      :class="[lowerBrightness]"
      :src="videoSrcDesktop || videoSrc"
      :poster="posterUrlDesktop || posterUrl"
    />
  </div>
</template>

<script>
import Vue2Video from '@made-people/vue2-video'
import Headline from '~/components/headline/Headline'
import Link from '~/components/link/Link.vue'
import GridMixin from '~/mixins/GridMixin.js'

export default {
  name: 'VideoHero',
  components: {
    Headline,
    Link,
    Vue2Video,
  },
  props: {
    ...GridMixin.props,
    aspectRatio: {
      type: String,
      required: false,
      default: 'aspect-portrait',
    },
    aspectRatioDesktop: {
      type: String,
      required: false,
      default: 'lg:aspect-20/9',
    },
    alt: {
      type: String,
      required: false,
      default: '',
    },
    hideMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
    videoSrc: {
      type: String,
      required: false,
      default: '',
    },
    videoSrcDesktop: {
      type: String,
      required: false,
      default: '',
    },
    hideDesktop: {
      type: Boolean,
      required: false,
      default: false,
    },
    columnsMobile: {
      type: String,
      required: false,
      default: 'col-span-4',
    },
    columnsDesktop: {
      type: String,
      required: false,
      default: 'lg:col-span-12',
    },
    alignMobile: {
      type: String,
      required: false,
      default: 'text-left',
    },
    alignDesktop: {
      type: String,
      required: false,
      default: 'lg:text-left',
    },
    poster: {
      type: Object,
      required: false,
      default: null,
    },
    posterDesktop: {
      type: Object,
      required: false,
      default: null,
    },
    color: {
      type: String,
      required: false,
      default: null,
    },
    mobileSwipeable: {
      type: Boolean,
      required: false,
      default: false,
    },
    link: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    marginBottom: {
      type: String,
      required: false,
      default: 'mb-0',
    },
    headlines: {
      type: Array,
      required: false,
      default: () => [],
    },
    headlinePositionMobile: {
      type: String,
      required: false,
      default: 'top-left',
    },
    paddingHorizontalDesktop: {
      type: String,
      required: false,
      default: 'lg:px-0',
    },
    headlinePositionDesktop: {
      type: String,
      required: false,
      default: 'top-left',
    },
    links: {
      type: Array,
      required: false,
      default: () => [],
    },
    linksPositionMobile: {
      type: String,
      required: false,
      default: 'bottom-left',
    },
    linksPositionDesktop: {
      type: String,
      required: false,
      default: 'bottom-left',
    },
  },
  computed: {
    lowerBrightness: ({ link }) =>
      link.url !== ''
        ? 'brightness-75 group-hover:brightness-100'
        : 'brightness-100',
    isDesktop: () =>
      typeof window !== 'undefined' ? window.innerWidth > 1024 : false,
    posterUrlDesktop() {
      return this.getPosterUrl(this.posterDesktop, 1200)
    },
    posterUrl() {
      return this.getPosterUrl(this.poster, 600)
    },
  },
  mounted() {
    document.body.addEventListener('touchstart', this.videoAutoplayListener)
  },
  beforeDestroy() {
    document.body.removeEventListener('touchstart', this.videoAutoplayListener)
  },
  methods: {
    videoAutoplayListener() {
      const video = this.$refs.video.$el
      const isVideoPlaying = !!(
        video.currentTime > 0 &&
        !video.paused &&
        !video.ended &&
        video.readyState > 2
      )

      if (isVideoPlaying) return
      video.play()
    },
    getPosterUrl(poster, width) {
      const filename = poster?.filename?.trim() || undefined
      if (filename === undefined) {
        return undefined
      }

      return `${this.$imgproxy.transform(
        filename,
        `resize:fit/width:${width}/quality:60`
      )}.webp`
    },
    contentPosition(mobileValue, desktopValue) {
      const mobile = {
        'top-left': 'justify-start items-start text-left',
        'top-center': 'justify-start items-center text-center',
        'top-right': 'justify-start items-end text-right',
        'center-left': 'justify-center items-start text-left',
        'center-center': 'justify-center items-center text-center',
        'center-right': 'justify-center items-end text-right',
        'bottom-left': 'justify-end items-start text-left',
        'bottom-center': 'justify-end items-center text-center',
        'bottom-right': 'justify-end items-end text-right',
      }
      const desktop = {
        'top-left': 'lg:justify-start lg:items-start lg:text-left',
        'top-center': 'lg:justify-start lg:items-center lg:text-center',
        'top-right': 'lg:justify-start lg:items-end lg:text-right',
        'center-left': 'lg:justify-center lg:items-start lg:text-left',
        'center-center': 'lg:justify-center lg:items-center lg:text-center',
        'center-right': 'lg:justify-center lg:items-end lg:text-right',
        'bottom-left': 'lg:justify-end lg:items-start lg:text-left',
        'bottom-center': 'lg:justify-end lg:items-center lg:text-center',
        'bottom-right': 'lg:justify-end lg:items-end lg:text-right',
      }
      return mobile[mobileValue] + ' ' + desktop[desktopValue]
    },
  },
}
</script>
